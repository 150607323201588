import React, {useRef, useState} from 'react';
import {Box, Button, Container, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import Footer from "./Footer";
import MainImg from "./imgs/main-wall3";
import BD from "./imgs/bd_job.jpeg";
import Flutter from "./imgs/flutter_job.jpeg";
import FullStack from "./imgs/fs_job.jpeg";
import {DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog} from "@mui/joy";

const Careers = () => {
    const [open, setOpen] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);

    const handleOpen = (Item) => {
        setOpen(true);
        setSelectedTitle(Item);
    };

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const ItemBox = styled(Paper)(({ theme }) => ({
        border: 'solid 1px',
        borderRadius: '15px',
        backgroundColor: '#32EE88FF',
        color: '#022031FF',
        borderColor: 'rgba(0,0,0,0.24)',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'start',
        boxShadow: 4,
        boxShadowColor: '#32EE88FF',
        elevation: true,
        ...theme.typography.body2,
        padding: theme.spacing(3),
    }));

    const sxStyleDetailsText = {
        fontSize: '18px',
        fontFamily: 'sans-serif',
        mt: 2,
    }

    const sxGridMain = {
        my: '2rem',
        mx: '0.5rem',
    }

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"},
        fontWeight: 'bold'
    }

    const sxGridInternal = {
        backgroundColor: '#022031FF',
        border: "solid 1px #32EE88FF",
        color: '#32EE88FF',
        borderRadius: '10px',
        m: '1rem',
        py: '1.5rem',
        px: '1rem',
        transition: '0.7s',
        "&:hover" : {
          backgroundColor: '#32EE88FF',
          color: '#022031FF',
        },
    }

    const sxStyleTypography = {
        color: '#32EE88FF'
    }

    const sxStyleImgs = {
        position: 'relative',
        height: '100%',
        width: '100%',
        borderRadius: '15px',
        objectFit: 'cover',
        cursor: 'pointer',
        '@media (max-width: 600px)': {
            width: '5%',
        },
        '@media (min-width: 601px) and (max-width: 960px)': {
            width: '60%',
        },
        '@media (min-width: 961px)': {
            width: '40%',
        },
    }

    const jobsInformation = [
        {
          title: 'Business Development Officer',
          details: 'Codeloci Software House is seeking a dynamic Business Development Officer with 2-3 years of experience ' +
              'to drive our strategic growth initiatives. This pivotal role involves identifying new business ' +
              'opportunities, fostering client relationships, and crafting innovative strategies to expand our market ' +
              'presence. The ideal candidate should possess a proven track record in sales, exceptional communication ' +
              'skills, and a deep understanding of market trends. Working closely with cross-functional teams, the ' +
              'Business Development Officer will play a key role in defining and executing strategies that align with ' +
              'the company\'s objectives, fostering partnerships, and driving revenue growth.',
            img: BD
        },

        {
            title: 'Flutter Developer',
            details: 'Codeloci Software House is looking for a skilled Flutter Developer with 2-3 years of experience in ' +
                'mobile app development. As a Flutter Developer, you will be instrumental in crafting engaging and ' +
                'intuitive mobile applications for both Android and iOS platforms. The ideal candidate will possess a ' +
                'strong understanding of Dart programming language, Flutter SDK, and mobile UI/UX principles. The role ' +
                'involves collaborating with designers and engineers to deliver robust, cross-platform applications that ' +
                'offer seamless user experiences. The Flutter Developer will contribute to the entire app development ' +
                'process, from ideation to deployment, ensuring the delivery of high-quality, performant mobile solutions.',
            img: Flutter
        },

        {
            title: 'Full Stack Developer',
            details: 'Join our team at Codeloci Software House as a Full Stack Developer with 2-3 years of hands-on experience ' +
                'in web development. This role involves spearheading the creation and maintenance of cutting-edge web ' +
                'applications, requiring expertise in both front-end and back-end technologies. The ideal candidate will ' +
                'demonstrate proficiency in JavaScript, React, Node.js, and various databases, translating design ' +
                'requirements into seamless, user-centric experiences. Collaborating within an agile environment, the ' +
                'Full Stack Developer will contribute to the entire software development lifecycle, from concept and ' +
                'design to deployment, ensuring scalable and high-performance applications that meet client needs.\n',
            img: FullStack
        },
    ]

    const sxModalDesign = {
        backgroundColor: '#022031FF',
        color: '#32EE88FF',
        border: 'solid 1px #32EE88FF',
    }

    const sxModalButtonDesign = {
        backgroundColor: '#022031FF',
        color: '#32EE88FF',
        border: 'solid 1px #32EE88FF',
        transition: '0.7s',
        "&:hover" : {
            borderColor: '#32EE88FF',
            backgroundColor: '#32EE88FF',
            color: '#022031FF',
        }
    }

    const HiddenFileInput = styled('input')({
        display: 'none',
    });

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file ? file.name : null);
    };

    const handleFormSubmit = () => {
            let experience = document.getElementById("experience").value;
            let comment = document.getElementById("comment").value;

            const email = 'hr@codeloci.com';
            const subject = encodeURIComponent('Application for ' + selectedTitle);
            const body = encodeURIComponent(`Years of Experience: ${experience}\nPosition: ${selectedTitle}\nComment: ${comment}`);

            const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;

            const newWindow = window.open(gmailUrl, '_blank');

            if (newWindow) {
                newWindow.focus();
            } else {
                alert('Please Allow popups to open Gmail.');
            }
    };

    return (
     <>
         <Box sx={{height: '45vh',backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
             <Container sx={{height: 'inherit'}}>
                 <CustomBox sx={{height: 'inherit', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                     <Typography variant={'h5'} sx={sxStyleTypography}>Careers</Typography>
                     <Typography variant={'h1'} sx={{...sxStyleTypography, ...sxHeaderSize}}>CodeLoci</Typography>
                 </CustomBox>
             </Container>
         </Box>
        <CustomBox>
            <Container maxWidth={'lg'}>
                <Grid container rowSpacing={2} sx={sxGridMain}>
                    {jobsInformation.map((Item) => { return <ItemBox sx={sxGridInternal}>
                        <Grid container sx={{height: 'inherit'}}>
                            <Grid item xs={12} md={3} sx={{height: 'inherit'}}>
                                <img onClick={() => handleOpen(Item.title)} src={Item.img} alt="jobs" style={sxStyleImgs} />
                            </Grid>
                            <Grid item xs={12} md={9} sx={{px: {xs: '0.5rem', md: '2rem'}}}>
                                <Typography variant={'h4'} sx={{fontWeight: 'bold'}}>{Item.title}</Typography>
                                <Typography sx={sxStyleDetailsText} variant={'body-2'}>{Item.details}</Typography>
                            </Grid>
                        </Grid>
                    </ItemBox>})}
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <ModalDialog sx={sxModalDesign}>
                            <DialogTitle>Send CV</DialogTitle>
                            <DialogContent sx={sxStyleTypography}>Fill in the information.</DialogContent>
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    setOpen(false);
                                    handleFormSubmit();
                                }}
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel sx={sxStyleTypography}>Experience in Years</FormLabel>
                                        <input
                                            id="experience"
                                            autoFocus
                                            required
                                            style={{...sxModalDesign, padding: '0.55rem 0.65rem', borderRadius: '5px'}}
                                            />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel sx={sxStyleTypography}>Position</FormLabel>
                                        <Input
                                            required
                                            sx={sxModalDesign}
                                            defaultValue={selectedTitle}
                                            disabled={true}></Input>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel sx={sxStyleTypography}>Comment</FormLabel>
                                        <input
                                            type="text"
                                            id="comment"
                                            required
                                            style={{...sxModalDesign, padding: '0.55rem 0.65rem', borderRadius: '5px'}}
                                        ></input>
                                    </FormControl>
                                    <FormControl>
                                        <Button sx={sxModalButtonDesign} variant="contained" onClick={handleButtonClick}>
                                            Select File
                                        </Button>
                                        <span>{selectedFile}</span>
                                        <HiddenFileInput
                                            ref={fileInputRef}
                                            type="file"
                                            onChange={handleFileInputChange}
                                        />
                                    </FormControl>
                                    <Button sx={sxModalButtonDesign} type="submit">Send</Button>
                                </Stack>
                            </form>
                        </ModalDialog>
                    </Modal>
                </Grid>
            </Container>
        </CustomBox>
         <Footer></Footer>
     </>
 )
}



export default Careers;