import React, {useEffect} from 'react';
import {Box, Container, Grid, styled, Typography} from "@mui/material";
import {LocationOnRounded, PhoneRounded, MailRounded} from '@mui/icons-material';
import footerLogo from "./imgs/logo-lite.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {

    useEffect(() => {
        AOS.init({duration: 1000});
    }, [])

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        backgroundColor: '#022031',
        minHeight: '40vh',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(5),
    }));

    const sxText = {
        color: '#32EE88FF',
    };

    const styleLogo = {
        height: '100px',
        width: '100px',
    };

    const sxLinksStyle = {
        color: '#32EE88FF',
        fontFallback: 'sans-serif',
        fontSize: '18px',
    };

    return(
        <CustomBox>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box data-aos="fade-down" data-aos-delay="250" display="flex" height="100%" flexDirection="column" alignItem="center" textAlign="center" justifyContent="center">
                            <span><img src={footerLogo} alt="footer-logo" style={styleLogo}/></span>
                            <Typography variant="h3" sx={sxText}>CodeLoci</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box data-aos="fade-down" data-aos-delay="250" display="flex" height="100%" py='10px' flexDirection="column" alignItem="center" justifyContent="center" ml="3rem">
                            <Typography variant="body2" display="flex" alignItems="center" alignSelf="start" my="1rem">
                                <MailRounded sx={{mr: 1, color: '#32EE88FF'}} fontSize="small" />
                                <a style={sxLinksStyle}>hr@codeloci.com</a>
                            </Typography>
                            <Typography variant="body2" display="flex" alignItems="center" alignSelf="start">
                                <PhoneRounded sx={{mr: 1, color: '#32EE88FF'}} fontSize="small" />
                                <Typography style={sxLinksStyle}>+92-323-7560427</Typography>
                            </Typography>
                            <Typography variant="body2" display="flex" alignItems="center" alignSelf="start" my="1rem">
                                <LocationOnRounded sx={{mr: 1, color: '#32EE88FF'}} fontSize="small" />
                                <Typography style={{...sxLinksStyle, textAlign: 'start', alignSelf: 'center'}}>Office# 101, Plaza 83, Neelam Commercial, DC Colony, Rahwali Cantonment, Gujranwala</Typography>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={3}>
                    <Typography variant="body2" align="center" sx={sxLinksStyle}>
                        &copy; 2023 CodeLoci. All Rights Reserved.
                    </Typography>
                </Box>
            </Container>
        </CustomBox>
    )
}

export default Footer;