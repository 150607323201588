import React, {useState} from 'react';
import {
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {MenuRounded} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';

const HeaderDrawer = () =>{
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const handleNavigation = (route) => {
        setOpen(false);
        navigate(route); // Redirect to the specified route
    };
    return(
        <>
        <Drawer PaperProps={{sx: {width: '90%',marginY: '10px',marginX: 'auto', alignItems: 'center', display: 'flex', backgroundColor: '#022031'}}} anchor={'top'} open={open} onClose={() => setOpen(false)}>
            <List sx={{width: '100%'}}>
                <ListItemButton onClick={() => handleNavigation('/Home')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{textAlign: 'center', width: '100%', color : '#32EE88FF'}}>Home</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/Services')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>Services</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/Team')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>Our Team</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/Learn')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>Learn</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/Careers')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>Careers</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/Contact')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>Contact</ListItemText>
                    </ListItemIcon>
                </ListItemButton>

                <ListItemButton onClick={() => handleNavigation('/About')} sx={{width: '100%'}}>
                    <ListItemIcon sx={{width: '100%'}}>
                        <ListItemText sx={{width: '100%', textAlign: 'center', color : '#32EE88FF'}}>About</ListItemText>
                    </ListItemIcon>
                </ListItemButton>
            </List>
        </Drawer>

        <IconButton sx={{marginLeft: 'auto', color: '#32EE88FF'}} onClick={() => setOpen(!open)}>
            <MenuRounded />
        </IconButton>
        </>
    )
}

export default HeaderDrawer;