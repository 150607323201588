import React, {useState} from 'react';
import { Box, Button, Container, Grid, styled } from "@mui/material";
import pdt from '../imgs/pdt-mgnt.jpg';
import pjt from '../imgs/pjm_2.jpeg';
import web from '../imgs/web-dev.jpg';
import mbl from '../imgs/mbl-dev.jpg';
import seo from '../imgs/seo_2.jpeg';
import grps from '../imgs/gd_2.jpeg';

const HomeHexagon = () => {

    const imgs = [
        pjt, pdt, web, mbl, seo, grps
    ]

    const [selectedImage, setSelectedImage] = useState(imgs[0]);

    const handleButtonClick = (index) => {
        setSelectedImage(imgs[index]);
    };


    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        marginTop: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const Hexagon = styled('div')(({ theme }) => ({
        position: 'relative',
        backgroundColor: '#022031FF',
        margin: '50px auto',
        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)',
        animation: 'rotateHexagon 360s linear infinite',
        '@keyframes rotateHexagon': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' },
        },
    }));

    const ButtonCircle = styled(Button)(({ theme }) => ({
        position: 'absolute',
        width: '75px',
        height: '75px',
        backgroundColor: '#022031FF',
        color: '#32EE88FF',
        border: 'solid 2px #32EE88FF',
        borderRadius: '50%',
        transition: '0.7s',
        "&:hover" :{
            color: '#022031FF',
            borderColor: '#022031FF',
            backgroundColor: '#32EE88FF',
        }
    }));

    const sxHexagonSize = {
        width: {xs: '325px', md: '400px'},
        height: {xs: '325px', md: '450px'},
    }

    const sxStyleBox = {
        display: 'flex',
        backgroundColor: '#32EE88FF',
        position: 'absolute',
        width: {xs: '150px', md: '250px'},
        height: {xs: '150px', md: '250px'},
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 0px rgba(50,238,136,0.5)',
        textAlign: 'center',
        animation: 'rotateBox 360s linear infinite',
        '@keyframes rotateBox': {
            from: { transform: 'rotate(360deg)' },
            to: { transform: 'rotate(0deg)' },
        },
    }

    const sxTextBtn = {
        animation: 'rotateButtonCircle 360s linear infinite',
        '@keyframes rotateButtonCircle': {
            from: { transform: 'rotate(360deg)' },
            to: { transform: 'rotate(0deg)' },
        },
    }

    const styleImg = {
        position: 'cover',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
    }

    return (
        <CustomBox>
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Hexagon sx={sxHexagonSize}>
                            <ButtonCircle sx={{ top: {xs: '1.5%', md: '1.5%'}, left: {xs: '38.5%', md: '40.5%'} , ...sxTextBtn}} onClick={() => handleButtonClick(0)}>Pjt Mgt</ButtonCircle>
                            <ButtonCircle sx={{ top: {xs: '21%', md: '22%'}, right: '0%', ...sxTextBtn }} onClick={() => handleButtonClick(1)}>Pdt Mgt</ButtonCircle>
                            <ButtonCircle sx={{ top: {xs: '21%', md: '22%'}, left: '0%', ...sxTextBtn}} onClick={() => handleButtonClick(2)}>Web Dev</ButtonCircle>
                            <Box sx={{bottom: {xs: '27%', md: '22.5%'}, right: {xs: '26%',md: '18.75%'}, ...sxStyleBox}}><img style={styleImg} src={selectedImage} alt="hexagone_image" /></Box>
                            <ButtonCircle sx={{ bottom: {xs: '20.5%', md: '22%'}, right: '0%', ...sxTextBtn}} onClick={() => handleButtonClick(3)}>Mbl Dev</ButtonCircle>
                            <ButtonCircle sx={{ bottom: {xs: '20.5%', md: '22%'}, left: '0%', ...sxTextBtn }} onClick={() => handleButtonClick(4)}>SEO</ButtonCircle>
                            <ButtonCircle sx={{ bottom: {xs: '1.5%', md: '1.5%'}, right: {xs: '38.5%', md: '40.5%'}, ...sxTextBtn }} onClick={() => handleButtonClick(5)}>Grps Dsgn</ButtonCircle>
                        </Hexagon>
                    </Grid>
                </Grid>
            </Container>
        </CustomBox>
    );
};

export default HomeHexagon;
