import React, {useState} from 'react';
import MainImg from "./imgs/main-wall2";
import {Box, Container, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import pgt from "./imgs/pjm_1.jpeg";
import pdt from "./imgs/pm_1.jpeg";
import wdev from "./imgs/wd_1.jpeg";
import mdev from "./imgs/md_1.jpeg";
import seoi from "./imgs/seo_1.jpeg";
import mkt from "./imgs/m_1.jpeg";
import dsgn from "./imgs/gd_1.jpeg";
import Footer from "./Footer";

const Learn = () => {

    const learnCards = [
        {
            img: pgt,
            heading: 'Project Management',
            details: 'Our project management services ensure efficient planning, execution, and successful completion of projects, helping you achieve your goals on time and within budget.',
        },
        {
            img: pdt,
            heading: 'Product Management',
            details: 'We provide expert product management services, guiding the entire lifecycle of your products, from inception to market success, ensuring they resonate with your audience and drive business growth.',
        },
        {
            img: wdev,
            heading: 'Web Development',
            details: 'We specialize in web development, creating responsive and user-friendly websites tailored to your specific needs, enhancing your online presence and functionality.',
        },
        {
            img: mdev,
            heading: 'Mobile App',
            details: 'Our mobile app development services cover the entire spectrum, from ideation to deployment, ensuring you have a powerful mobile presence on iOS and Android platforms.',
        },
        {
            img: seoi,
            heading: 'SEO',
            details: 'We offer SEO services to boost your online visibility and rankings, optimizing your website to attract more organic traffic and potential customers.',
        },
        {
            img: mkt,
            heading: 'Marketing',
            details: 'Our marketing strategies are designed to increase your brands reach and engagement, leveraging various channels and tactics to grow your customer base and revenue.',
        },
        {
            img: dsgn,
            heading: 'Graphics Designing',
            details: 'We provide creative graphic design services, crafting visually appealing designs for your branding, marketing materials, and online presence, leaving a lasting impression on your audience.',
        },
    ];

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const ItemBox = styled(Paper)(({ theme }) => ({
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'start',
        boxShadow: 4,
        boxShadowColor: '#32EE88FF',
        elevation: 4,
        ...theme.typography.body2,
        paddingBottom: theme.spacing(3),
        backgroundColor: '#022031FF',
        color: '#32EE88FF',
        border: 'solid 1px #32EE88FF',
        transition: '0.7s',
        '&:hover': {
            backgroundColor: '#32EE88FF',
            color: '#022031FF',
        },
    }));

    const sxStyleTypography = {
        color: '#32EE88FF'
    }

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"},
        fontWeight: 'bold'
    }

    const sxMainBoxStyle = {
        py: '5rem',
        backgroundColor: '#022031FF',
    }

    const StyleImgs = {
        position: 'relative',
        height: '350px',
        width: '100%',
        borderRadius: '5px',
    }

    return(
        <>
            <Box sx={{height: '45vh',backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container sx={{height: 'inherit'}}>
                    <CustomBox sx={{height: 'inherit', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                        <Typography variant={'h5'} sx={sxStyleTypography}>Courses</Typography>
                        <Typography variant={'h1'} sx={{...sxStyleTypography, ...sxHeaderSize}}>CodeLoci</Typography>
                        <Typography variant={'h3'} sx={{...sxStyleTypography, fontWeight: 'bold'}}>Coming Soon</Typography>
                    </CustomBox>
                </Container>
            </Box>
            <Box sx={sxMainBoxStyle}>
                <Container>
                    <Grid container spacing={2}>
                        {learnCards.map((Item) => { return <Grid item xs={12} sm={6} md={4}>
                            <ItemBox>
                                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                    <img style={StyleImgs} src={Item.img} alt={'card'}/>
                                    <Typography variant={'h5'}>{Item.heading}</Typography>
                                </Stack>
                            </ItemBox>
                        </Grid>})}
                    </Grid>
                </Container>
            </Box>
            <Footer></Footer>
        </>
    )
}

export default Learn;