import {Box, Container, Stack, styled, Typography} from "@mui/material";
import React, {useEffect} from "react";
import CLVod from '../imgs/codeloci_vid.mp4';
import AOS from "aos";
import 'aos/dist/aos.css';

const HomeIntro = () => {
    useEffect(() => {
        AOS.init({duration: 1000});
    }, [])

    const sxBox = {
        width: 'inherit',
        backgroundColor: '#022031FF',
        borderRadius: '15px',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        elevation: 4,
        py: '0px',
        px: '0px',
    };

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        marginTop: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const styleVideoPlayer = {
        height: {xs: '200px', sm: '250px', md: '300px', lg: '550px', xl: '600px'},
        width: '100%',
        borderRadius: '5px',
        px: '0px',
        py: '0px',
    }

    const sxTypoText= {
        alignItems: 'center',
        textAlign: 'start',
        fontFamily: 'sans-serif',
        color: '#32EE88FF',
        borderRadius: '5px',
        border: 'solid 1px #32EE88FF',
        backgroundColor: '#022031FF',
        py: '2rem',
        px: '2rem',
        transition: 'background 0.7s color 0.7s',
        "&:hover" : {
            backgroundColor: '#32EE88FF',
            color: '#022031FF',
        }
    }

    return(
        <CustomBox>
            <Container>
                <Stack direction='column' alignItems='center' justifyContent='center' spacing={4} paddingY={4}>
                    <Typography data-aos="fade-down" variant={"body-2"}
                           sx={{alignItems: 'center',
                               textAlign: 'center',
                               fontWeight: 'bold',
                               fontFamily: 'sans-serif',
                               color: '#022031',
                               borderRadius: '5px',
                               backgroundColor: '#32EE88FF',
                               py: '5px',
                               px: '20px'}}
                        >Origination
                        </Typography>
                    <Box sx={sxBox} data-aos="fade-down" data-aos-delay="250">
                        <video style={styleVideoPlayer} loop controls="true">
                            <source src={CLVod} type="video/mp4"/>
                        </video>
                    </Box>
                        <Typography data-aos="fade-down" data-aos-delay="250" variant={'body-2'} sx={sxTypoText}>
                            CodeLoci is a dynamic and innovative software house dedicated to empowering businesses through
                            cutting-edge IT solutions. With a passion for technology and a commitment to excellence, CodeLoci
                            specializes in delivering tailored software development, web applications, and digital solutions that
                            drive business growth and efficiency. Our team of skilled professionals combines creativity and technical
                            expertise to craft solutions that align with the unique needs of each client. At CodeLoci, we embrace the
                            latest technologies to create robust and scalable software, ensuring our clients stay ahead in today's
                            fast-paced digital landscape. Partner with CodeLoci for a transformative journey in harnessing the power
                            of technology for your business success.
                        </Typography>
                </Stack>
            </Container>
        </CustomBox>
    )
}

export default HomeIntro;