import React, {useEffect} from 'react';
import {Box, Grid, styled, Typography} from "@mui/material";
import pgt from '../imgs/pjt.svg';
import pdt from '../imgs/pdt.svg';
import mkt from '../imgs/mkt.svg';
import wdev from '../imgs/wd.svg';
import mdev from '../imgs/md.svg';
import seoi from '../imgs/seo.svg';
import dsgn from '../imgs/gd.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeProjectCards = () => {
    useEffect(() => {
        AOS.init({duration: 1000});
    }, [])

    const profileCards = [
        {
            img: pgt,
            heading: 'Project Management',
            details: 'Our project management services ensure efficient planning, execution, and successful completion of projects, helping you achieve your goals on time and within budget.',
        },
        {
            img: pdt,
            heading: 'Product Management',
            details: 'We provide expert product management services, guiding the entire lifecycle of your products, from inception to market success, ensuring they resonate with your audience and drive business growth.',
        },
        {
            img: wdev,
            heading: 'Web Development',
            details: 'We specialize in web development, creating responsive and user-friendly websites tailored to your specific needs, enhancing your online presence and functionality.',
        },
        {
            img: mdev,
            heading: 'Mobile App',
            details: 'Our mobile app development services cover the entire spectrum, from ideation to deployment, ensuring you have a powerful mobile presence on iOS and Android platforms.',
        },
        {
            img: seoi,
            heading: 'SEO',
            details: 'We offer SEO services to boost your online visibility and rankings, optimizing your website to attract more organic traffic and potential customers.',
        },
        {
            img: mkt,
            heading: 'Marketing',
            details: 'Our marketing strategies are designed to increase your brands reach and engagement, leveraging various channels and tactics to grow your customer base and revenue.',
        },
        {
            img: dsgn,
            heading: 'Graphics Designing',
            details: 'We provide creative graphic design services, crafting visually appealing designs for your branding, marketing materials, and online presence, leaving a lasting impression on your audience.',
        },
    ];

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#022031FF',
        minHeight: '50vh',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(5),
        marginTop: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const sxCircularGrid = {
        height: '100%',
        width: '100%',
        marginBottom: '1rem',
        backgroundColor: '#022031FF',
        borderRadius: '3px',
    }

    const sxGridCard =
        {
            border: 'solid 1px #32EE88FF',
            m: '1rem',
            pb: '2rem',
            borderRadius: '5px',
            backgroundColor: "#022031FF",
            color: "#32EE88FF",
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            boxShadow: 4,
            transition: "0.7s",
            "&:hover": {
                border: 'solid 1px #022031FF',
                backgroundColor: "#32EE88FF",
                color: "#022031FF",
                boxShadow: '0px 0px 8px rgba(50,238,136,0.75)'
            },
        }

    const sxGridOuterCircle = {
        justifyContent: 'center',
        display: 'flex',
    }

    const styleImages = {
        borderRadius: '3px',
        height: {xs: "150px", sm: "160px", md: '200px'},
        width: {xs: '250px', sm: "200px", md: '225px', lg: "300px"},
    }


    return(
        <CustomBox>
            <Box data-aos="fade-down" sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '4rem'}}>
                <Typography
                    sx={{alignItems: 'center',textAlign: 'center',fontWeight: 'bold',color: '#022031FF',
                    borderRadius: '5px', backgroundColor: '#32EE88FF', py: '5px', px: '20px'}} variant={'body2'}>
                    Our Services
                </Typography>
            </Box>
            <Grid container sx={{width: '90%', pb: '5rem', pt: '1rem', maxWidth: '1600px'}}>
                <Grid container item sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                    {profileCards.map((Item)=> { return <Grid item xs={12} sm={5} lg={3} sx={sxGridCard}>
                        <Grid item data-aos="fade-down" sx={sxGridOuterCircle}>
                            <Grid item sx={sxCircularGrid}>
                                <Box component={'img'} sx={styleImages} src={Item.img} alt="Img"/>
                            </Grid>
                        </Grid>
                        <Typography data-aos="fade-down" variant={'h5'} sx={{mt: 2}}>{Item.heading}</Typography>
                        <Typography data-aos="fade-down" variant={'body2'} sx={{px: 4, mb: 3, mt: 1, fontSize: '16px'}}>{Item.details}</Typography>
                    </Grid>})}
                </Grid>
            </Grid>
        </CustomBox>
    )
}

export default HomeProjectCards;