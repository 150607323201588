import React, {useState} from 'react';
import {AppBar, Box, Toolbar, Typography, useTheme, useMediaQuery, Tab, Tabs} from "@mui/material";
import mainIcon from './imgs/logo.svg';
import {Grid} from '@mui/material';
import HeaderDrawer from "./HeaderDrawer";
import {Link} from 'react-router-dom';

const Header = () => {
    const theme = useTheme();
    const screenSize = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState();

    const sxStyleBox = {
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    };

    const sxTypography = {
        color: '#32EE88FF',
        fontSize: {xs: '20px', md: '20px', xl: '25px'},
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        width: '100%'
    };

    const sxTab = {
        mx: '3px',
        px: '15px',
        borderRadius: '5px',
        border: 'solid 2px transparent',
        color: '#32EE88FF',
        transition: "background 0.7s, color 0.7s",
        underlineNone: 'none',
        underline: 'none',
        '&:hover': {
            border: 'solid 2px #32EE88FF',
            underlineNone: 'true',
        },

        '&:focus': {
            color: '#022031',
            border: 'solid 2px #32EE88FF',
            backgroundColor: '#93fabe',
            underlineNone: 'true',
        },

        '&:active': {
            color: '#022031',
            border: 'solid 2px #32EE88FF',
            backgroundColor: '#93fabe',
            underlineNone: 'true',
        },

        '&:Mui.selected': {
            color: '#022031',
            border: 'solid 2px #32EE88FF',
            backgroundColor: '#93fabe',
            underlineNone: 'true',
        },
    };

    return (
        <React.Fragment>
            <AppBar position="fixed">
                <Toolbar sx={{ background: "#022031" }}>
                    {!screenSize ?
                        <Grid container sx={{justifyContent: 'space-between'}} flexWrap="nowrap">
                        <Grid container item xs={4} sx={{marginLeft: {xs: '0px', md: '20px', width: 'inherit'}}}>
                            <Grid item display={'flex'}>
                                <img src={mainIcon} alt={'header-logo'} style={{
                                    height: '60px',
                                    width: '60px'
                                }} ></img>
                            </Grid>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <Typography sx={sxTypography}>CodeLoci</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={8} alignItems="center" justifyContent="end">
                            <Grid item>
                                <Box sx={sxStyleBox}>
                                    <Tabs TabIndicatorProps={{style: {backgroundColor: 'transparent',}}} value={value} onChange={(val) => setValue(val)}>
                                        <Tab sx={sxTab} label="Home" component={Link} to={"/Home"} />
                                        <Tab sx={sxTab} label="Services" component={Link} to={"/Services"} />
                                        <Tab sx={sxTab} label="Our Team" component={Link} to={"/Team"} />
                                        <Tab sx={sxTab} label="Learn" component={Link} to={"/Learn"} />
                                        <Tab sx={sxTab} label="Careers" component={Link} to={"/Careers"} />
                                        <Tab sx={sxTab} label="Contact" component={Link} to={"/Contact"} />
                                        <Tab sx={sxTab} label="About" component={Link} to={"/About"}/>
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                        :
                        <Grid container sx={{justifyContent: 'space-between'}}>
                                <Grid item xs={4} display={'flex'} alignItems={'center'} sx={{marginLeft: {xs: '0px', md: '20px'}}}>
                                    <img src={mainIcon} alt={'header-logo'} style={{
                                        height: '60px',
                                        width: '60px'
                                    }} ></img>
                                </Grid>
                                <Grid item xs={4} display={'flex'} sx={{width: '100%', textAlign: 'center'}} alignItems={'center'}>
                                    <Typography sx={sxTypography}>CodeLoci</Typography>
                                </Grid>
                            <Grid item xs={4} display={'flex'}>
                                <HeaderDrawer></HeaderDrawer>
                            </Grid>
                        </Grid>
                    }
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default Header;