import React, { useState } from 'react';
import {useMediaQuery, Box, Container, Grid, Paper, Stack, styled, Typography, useTheme} from "@mui/material";
import Footer from "./Footer";
import MainImg from "./imgs/main-wall1";
import pm from "./imgs/pm_2.jpeg";
import pj from "./imgs/pjm_2.jpeg";
import wd from "./imgs/wd_2.jpeg";
import md from "./imgs/md_2.jpeg";
import gd from "./imgs/gd_2.jpeg";
import dop from "./imgs/dev_ops_2.jpeg";
import seo from "./imgs/seo_2.jpeg";
import mk from "./imgs/m_2.jpeg";

const Services = () => {
    const [selectedTab, setSelectedTab] = useState(null);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        gap: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const ItemBox = styled(Paper)(({ theme }) => ({
        border: 'solid 1px',
        borderRadius: '5px',
        backgroundColor: '#32EE88FF',
        color: '#022031FF',
        borderColor: 'rgba(0,0,0,0.24)',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        boxShadow: 4,
        boxShadowColor: '#32EE88FF',
        elevation: true,
        ...theme.typography.body2,
        padding: theme.spacing(3),
        textAlign: 'center',
    }));

    const sxBoxStyle = {
        py: '0.75rem',
        px: '1rem',
        border: 'solid 1px #022031FF',
        borderRadius: '5px',
        elevation: true,
        boxShadow: 4,
        boxShadowColor: '#32EE88FF',
        cursor: 'pointer',
        transition: "background 0.7s, color 0.7s",
        "&:hover": {
            backgroundColor: "#32EE88FF",
            color: "#022031FF"
        },
    }

    const sxStyleTypography = {
        color: '#32EE88FF',
        textAlign: 'center',
    }

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"}
    }

    const sxStyleTypographyBottom = {
        fontFamily: 'sans-serif',
        fontSize: '18px',
    }

    const tabHeading = [
        {
            header: 'Product Management',
            img: pm,
        },
        {
            header: 'Project Management',
            img: pj,
        },
        {
            header: 'Web Development',
            img: wd,
        },
        {
            header: 'Mobile App',
            img: md,
        },
        {
            header: 'Graphics Designing',
            img: gd,
        },
        {
            header: 'Cloud Computing',
            img: seo,
        },
        {
            header: 'DevOps',
            img: dop,
        },
        {
            header: 'SEO',
            img: seo,
        },
        {
            header: 'Marketing',
            img: mk,
        },
    ]

    const tabInformation = [
        'At CodeLoci, our adept product management team acts as the driving force behind transformative innovations. We delve deep into understanding client requirements, market landscapes, and user expectations to craft a comprehensive product vision. Our collaborative approach involves stakeholders throughout the ideation and conceptualization phases, fostering an environment of creativity and alignment.\n' +
        '\n' +
        'We excel in not just envisioning the end product, but also strategizing the roadmap that leads to its successful launch. Our team meticulously plans, executes, and iterates through agile methodologies, ensuring adaptability in dynamic environments. Embracing the principles of Scrum or Kanban, we foster a culture of continuous improvement, refining strategies based on feedback loops and market insights.',
        'At CodeLoci, our distinguished project management team serves as the backbone for successful project execution. We adopt a comprehensive approach that encompasses meticulous planning, seamless execution, and vigilant oversight to ensure project success within stipulated timelines and budgets.\n' +
        '\n' +
        'Our project management experts possess a wealth of experience in orchestrating multifaceted projects across diverse industries. They adeptly navigate through complexities, effectively allocating resources, and mitigating risks, thereby ensuring project milestones are achieved with precision.\n' +
        '\n' +
        'We begin by collaborating closely with clients to gain a deep understanding of their objectives, expectations, and unique project requirements. This collaborative approach allows us to tailor project strategies that align seamlessly with the client\'s vision while addressing their specific needs.',
        'CodeLoci takes pride in crafting cutting-edge web solutions that stand at the intersection of innovation, functionality, and aesthetics. Our web development services are tailored to meet the diverse needs of businesses across industries, ranging from startups to enterprises.\n' +
        '\n' +
        'Our proficient team of web developers possesses expertise in a multitude of programming languages, frameworks, and platforms, enabling us to create bespoke websites that cater to our clients\' specific requirements. We begin by conducting in-depth consultations to grasp our clients\' objectives, target audience, and desired functionalities.\n' +
        '\n' +
        'Utilizing the latest technologies and best practices, we design responsive and visually captivating websites that provide an optimal user experience across devices. Whether it\'s a single-page application, e-commerce platform, content management system (CMS), or a complex enterprise solution, we ensure scalability, security, and performance are inherent in every line of code.',
        'CodeLoci pioneers in crafting high-performance mobile applications tailored to meet the diverse demands of modern businesses. Our mobile development services encompass native, hybrid, and cross-platform solutions, ensuring a seamless user experience across various devices and operating systems.\n' +
        '\n' +
        'Our adept team of mobile app developers possesses proficiency in leading technologies, frameworks, and tools, allowing us to create robust and feature-rich applications. We commence each project by conducting detailed consultations to understand our clients\' objectives, target audience, and specific functionalities required.\n' +
        '\n' +
        'Whether it\'s iOS, Android, or cross-platform development, we leverage industry-leading tools like React Native, Flutter, or native SDKs to build intuitive and visually appealing applications. We prioritize user-centric designs, ensuring an engaging interface coupled with seamless functionality and performance.',
        'At CodeLoci, our proficient graphics designing team blends creativity, innovation, and strategic thinking to craft visually captivating designs that resonate with our clients\' brand identities and storytelling.\n' +
        '\n' +
        'We begin by comprehensively understanding our clients\' brand ethos, target audience, and market positioning. Our collaborative approach involves close interaction with clients to capture their vision, values, and unique selling propositions, laying the foundation for impactful designs.\n' +
        '\n' +
        'Our design experts utilize industry-standard tools and techniques to create an array of visuals, including logos, UI/UX elements, multimedia content, and branding collaterals. We emphasize originality and creativity, ensuring that each design element aligns seamlessly with the client\'s brand message and identity.',
        'CodeLoci is at the forefront of empowering businesses with innovative cloud computing solutions that revolutionize operations, scalability, and security. We specialize in leveraging the full potential of cloud technologies to drive digital transformation and business growth.\n' +
        '\n' +
        'Our adept team of cloud experts possesses comprehensive knowledge across various cloud platforms, including but not limited to AWS (Amazon Web Services), Azure, Google Cloud Platform, and others. We work closely with clients to understand their unique business requirements, infrastructural needs, and future scalability goals.\n' +
        '\n' +
        'We offer an array of cloud services, including infrastructure migration, deployment, management, and optimization. Our approach begins with a thorough assessment of existing infrastructures, devising tailored strategies for seamless migration to cloud environments while ensuring minimal disruptions.',
        'CodeLoci revolutionizes software development practices through its comprehensive DevOps services, aimed at streamlining development cycles, fostering collaboration, and enhancing deployment efficiency.\n' +
        '\n' +
        'Our DevOps team comprises seasoned professionals well-versed in cutting-edge tools, methodologies, and practices. We facilitate a cultural shift that emphasizes collaboration, communication, and integration between development and operations teams.\n' +
        '\n' +
        'We begin by evaluating our clients\' existing workflows, identifying bottlenecks, and designing tailored DevOps strategies. Our approach incorporates automation, continuous integration (CI), continuous delivery (CD), and infrastructure as code (IaC) to accelerate software delivery while ensuring reliability and quality.',
        'CodeLoci specializes in crafting comprehensive SEO strategies aimed at boosting online visibility, increasing organic traffic, and enhancing search engine rankings for businesses across diverse industries.\n' +
        '\n' +
        'Our SEO experts meticulously analyze our clients\' websites, conduct thorough keyword research, and perform competitive analysis to identify opportunities and create tailored strategies. We focus on both on-page and off-page optimization techniques to maximize results.\n' +
        '\n' +
        'On-page optimization involves optimizing website elements such as meta tags, headings, content, and site structure to make them more search-engine-friendly. We prioritize creating high-quality, relevant, and engaging content that aligns with users\' search intent, thereby enhancing the website\'s authority and relevance.\n' +
        '\n' +
        'Off-page optimization strategies focus on building a robust backlink profile, establishing authoritative links from reputable websites, and enhancing the website\'s credibility in the eyes of search engines. We employ ethical link-building tactics that adhere to search engine guidelines, ensuring sustainable and long-term results.',
        'CodeLoci provides a comprehensive suite of marketing solutions aimed at amplifying brand presence, enhancing engagement, and driving business growth across digital landscapes.\n' +
        '\n' +
        'Our marketing team comprises skilled professionals well-versed in various marketing disciplines, including digital marketing, content strategy, social media management, analytics, and more. We collaborate closely with clients to understand their unique business goals, target audiences, and market positioning.',
    ]

    const StyleImgs = {
        position: 'relative',
        height: '550px',
        width: '100%',
        borderRadius: '5px',
    }

    const handleTabClick = (index) => {
        setSelectedTab(index);
    };

    return (
        <>
            <Box sx={{height: '45vh',backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container sx={{height: 'inherit'}}>
                    <CustomBox sx={{height: 'inherit', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                        <Typography variant={'h5'} sx={sxStyleTypography}>Services</Typography>
                        <Typography variant={'h1'} sx={{...sxStyleTypography, ...sxHeaderSize, fontWeight: 'bold'}}>CodeLoci</Typography>
                    </CustomBox>
                </Container>
            </Box>
            <CustomBox>
                <Container maxWidth="lg">
                    <Grid container sx={{ pt: '4rem'}}>
                        <Grid item xs={12}>
                            <ItemBox sx={{textAlign: 'start'}}>
                                <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                    CodeLoci is a software development company that provides modern solutions to businesses
                                    of all sizes. We have a team of experienced developers who are experts in the latest technologies.
                                    We offer a wide range of services, including:
                                    <ul>
                                        <li>
                                            Web Development
                                        </li>
                                        <li>
                                            Mobile App Development
                                        </li>
                                        <li>
                                            Custom Software Development
                                        </li>
                                        <li>
                                            Cloud Computing
                                        </li>
                                        <li>
                                            DevOps
                                        </li>
                                    </ul>
                                </Typography>
                            </ItemBox>
                        </Grid>
                    </Grid>
                </Container>
            </CustomBox>

            <CustomBox>
            <Container maxWidth="lg">
                <Grid container spacing={2} sx={{ py: '4rem' }}>
                    <Grid item xs={12} md={6} sx={{height: 'inherit'}}>
                        <ItemBox sx={{height: '100%'}}>
                            <Stack direction="column" sx={{height: 'inherit'}} justifyContent="space-between">
                                {tabHeading.map((Item, index) => {
                                    return (
                                        <Box
                                            sx={{
                                                ...sxBoxStyle,
                                                backgroundColor: selectedTab === index ? '#32EE88FF' : '#022031FF',
                                                color: selectedTab === index ? '#022031FF' : '#32EE88FF',
                                            }}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            <Typography sx={{fontWeight: 'bold'}} variant={'h5'}>{Item.header}</Typography>
                                            {isSmallScreen && selectedTab === index && (
                                                <Box py={2} sx={{textAlign: 'start'}}>
                                                    <Typography variant={'body-2'}>{tabInformation[index]}</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    )})}
                            </Stack>
                        </ItemBox>
                    </Grid>

                    {!isSmallScreen && (
                    <Grid item xs={12} md={6} sx={{height: 'inherit'}}>
                        <ItemBox sx={{height: '100%', justifyContent: 'center', alignSelf: 'center', textAlign: 'center', display: 'flex' }}>
                            <Stack direction="column" sx={{height: 'inherit'}} justifyContent="space-between">
                                {selectedTab !== null &&
                                    <img style={StyleImgs} src={tabHeading[selectedTab].img} alt={"service_card"}/>
                                }
                                <Typography variant="body2" sx={{lineHeight: '1.25rem', fontSize: '18px', textAlign: 'start'}}>
                                    {tabInformation[selectedTab]}
                                </Typography>
                            </Stack>
                        </ItemBox>
                    </Grid>)}
                </Grid>
            </Container>
        </CustomBox>
        <Footer></Footer>
        </>
    )
}

export default Services;
