import React from "react";
import ReactDOM from "react-dom"
import Header from "./Header";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import Services from "./Services";
import Contact from "./Contact";
import About from "./About";
import Careers from "./Careers";
import {Box} from "@mui/material";
import Learn from "./Learn";
import Team from "./Team";

const App = () => {
    return(
        <div>
            <Router basename="/">
                <Header />
                <Box sx={{marginTop: {xs: '48px', sm: '64px'}, mx: 0}}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/Home" element={<Home />} />
                    <Route path="/Services" element={<Services />} />
                    <Route path="/Team" element={<Team />} />
                    <Route path="/Learn" element={<Learn />} />
                    <Route path="/Careers" element={<Careers />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/About" element={<About />} />
                </Routes>
                </Box>
            </Router>
        </div>
    )
}

ReactDOM.render(
    <App />,
    document.querySelector('#root')
)