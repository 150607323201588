import React, {useEffect} from 'react';
import {Box, Container, styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import MainImg from "./imgs/main_wallpaper.jpeg";
import HomeAbout from "./HomePageComponents/HomeAbout";
import HomeServicesCards from "./HomePageComponents/HomeServicesCards";
import HomeInfo from "./HomePageComponents/HomeInfo";
import HomeIntro from "./HomePageComponents/HomeIntro";
import Footer from "./Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeHexagon from "./HomePageComponents/HomeHexagon";

const Home = () => {
    const theme = useTheme();
    const screenSize = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        AOS.init();
    }, [])

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        justifyContent: 'start',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const sxStyleTextMain = {
        textAlign: {xs: "center", md: 'start'},
        alignSelf: 'center',
        width: {xs: '100%', md: '75%'},
        color: '#ecfff4',
    };

    const sxStyleTextExtra = {
        fontSize: '20px',
        fontWeight: 'bold',
        mb: 4
    }

    const sxStyleText = {...sxStyleTextMain, ...sxStyleTextExtra};
    return(
        <div style={{backgroundColor: '#32EE88FF'}}>
            <Box data-aos="fade-down" duration="750" sx={{m: 0, backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container>
                    <CustomBox>
                        <Box data-aos="fade-up" duration="750" data-aos-delay="250" sx={{alignItems: 'start', width: {xs: '80%', md: '50%'}}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', my: 5}}>
                                <Typography variant={'body2'} sx={sxStyleText}>
                                    Welcome to <span style={{color: '#32EE88FF'}}>CodeLoci</span>
                                </Typography>
                                <Typography variant='h3' sx={sxStyleTextMain}>
                                    Enhance Your Business Growth With our IT Solutions and Smart One Stop-Shop for All Your IT Needs.
                                </Typography>
                                <Typography sx={{...sxStyleTextMain, fontSize: '18px', my: 3}} variant='h6'>
                                    Welcome to CodeLoci Software House, where innovation meets expertise. Discover
                                    cutting-edge solutions crafted to elevate your digital presence.
                                </Typography>
                            </Box>
                        </Box>
                    </CustomBox>
                </Container>
            </Box>
            {!screenSize && (
                <HomeHexagon></HomeHexagon>
            )}
            <HomeIntro></HomeIntro>
            <HomeAbout></HomeAbout>
            <HomeServicesCards></HomeServicesCards>
            {/*<HomeProjectCards></HomeProjectCards>*/}
            <HomeInfo></HomeInfo>
            <Footer></Footer>
        </div>
    )
}

export default Home;