import React, {useEffect} from 'react';
import {Box, Container, Grid, Paper, styled, Typography} from "@mui/material";
import imgicon from '../imgs/main-wall11.jpeg';
import imgsmoke from '../imgs/main-wall12';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ItemBox = styled(Paper)(({ theme }) => ({
    backgroundColor: '#022031FF',
    color: "#32EE88FF",
    border: 'solid 1px #32EE88FF',
    borderRadius: '5px',
    transition: "background 0.7s, color 0.7s",
    "&:hover": {
        backgroundColor: "#32EE88FF",
        color: "#022031FF"
    },
}));

const HomeInfo = () => {
    useEffect(() => {
        AOS.init({duration: 1000});
    }, [])

    let text = "We are deeply passionate about our work, driven by a genuine love for what we do. " +
        "Our unwavering ommitment is to deliver unparalleled client satisfaction through personalized guidance and " +
        "unwavering dedication to excellence. Our web development team operates in perfect harmony within a single " +
        "workspace, offering a well-coordinated approach. As a premier software house in Pakistan, we offer a " +
        "comprehensive range of services, including website development, SEO traffic enhancement, conversion " +
        "optimization, logo and icon design, and modernization of online resources. Our mission as a software house " +
        "is to set the industry standard for top-notch services in the online market.";

    let text2 = "CodeLoci is a dynamic and innovative software house, comprising a team of passionate professionals " +
        "dedicated to pushing the boundaries of technology. We take immense pride in our work, offering tailored solutions " +
        "to meet the diverse needs of our clients. With a collaborative and well-coordinated team of web developers, " +
        "designers, and digital strategists, we consistently deliver high-quality results. Our services encompass a wide " +
        "spectrum, including web development, SEO optimization, creative design, and resource modernization, all geared " +
        "toward enhancing our clients' online presence. At CodeLoci, our core mission is to be a leading force in the " +
        "software industry, setting new standards for excellence in the ever-evolving digital landscape.";

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        minHeight: '50vh',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const sxMainGrid = {

    };

    const sxInternalGridText = {
        px: {xs: '1.5rem', md: '3rem'},
        py: {xs: '1.5rem', md: '3rem'},
        borderRadius: '5px',
    }

    const sxImage = {
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        alignSelf: 'center',
    }

    return (
        <CustomBox>
            <Container maxWidth={'lg'} sx={{py: {xs: 5, md: 10}}}>
                <Grid container sx={sxMainGrid} spacing={{xs: 0, md: 1}}>
                    <Grid container item data-aos="fade-down" sx={sxInternalGridText} xs={12} md={6}>
                        <ItemBox>
                            <img style={sxImage} src={imgicon} alt="Image"></img>
                        </ItemBox>
                    </Grid>

                    <Grid data-aos="fade-down" sx={sxInternalGridText} container item xs={12} md={6}>
                        <ItemBox>
                            <Grid container item data-aos="fade-down" sx={sxInternalGridText}>
                            <Typography variant={'h5'}>Software Development Services</Typography>
                            <Typography variant={'body2'}>{text}</Typography>
                            </Grid>
                        </ItemBox>
                    </Grid>
                </Grid>

                <Grid container sx={sxMainGrid} spacing={{xs: 0, md: 2}}>
                    <Grid data-aos="fade-down" sx={sxInternalGridText} container item xs={12} md={6}>
                        <ItemBox>
                            <Grid container item data-aos="fade-down" sx={sxInternalGridText}>
                                <Typography variant={'h5'}>Who are we?</Typography>
                                <Typography variant={'body2'}>{text2}</Typography>
                            </Grid>
                        </ItemBox>
                    </Grid>

                    <Grid data-aos="fade-down" sx={sxInternalGridText} container item xs={12} md={6}>
                        <ItemBox>
                                <img style={sxImage} src={imgsmoke} alt="Image"></img>
                        </ItemBox>
                    </Grid>
                </Grid>
            </Container>
        </CustomBox>
    )
}

export default HomeInfo;