import React, {useState} from 'react';
import MainImg from "./imgs/main-wall5";
import {Box, Button, Container, Grid, styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import CLLogo from './imgs/logo.svg';
import Footer from "./Footer";


const About = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [showGrid, setShowGrid] = useState(false);

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const CustomButton = styled(Button)(({ theme }) => ({
        color: '#32EE88FF',
        backgroundColor: '#022031FF',
        border: 'solid 1px #32EE88FF',
        transition: '0.7s',
        boxShadow: '0px 4px 8px rgba(50,238,136,0.5)',
        '&:hover': {
            color: '#022031FF',
            backgroundColor: '#32EE88FF',
            boxShadow: '0px 4px 8px rgba(50,238,136,.5)',
        },
    }));

    const sxStyleTypography = {
        color: '#32EE88FF'
    }

    const sxStyleTypographyBottom = {
        fontFamily: 'sans-serif',
        fontSize: {xs: '18px', md: '20px', lg: '22px', xl: '24px'},

    }

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"},
        fontWeight: 'bold'
    }

    const sxStyleImage = {
        height: '100px',
        width: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center',
    }

    const sxMainGrid = {
        px: '2rem',
        py: '2rem',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'solid 1px #32EE88FF',
        color: '#32EE88FF',
        borderRadius: '5px',
    }

    const handleButtonClick = () => {
        setShowGrid(prevState => !prevState);
    };

    return (
        <Box sx={{backgroundColor: '#022031FF'}}>
            <Box sx={{height: '45vh',backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container sx={{height: 'inherit'}}>
                    <CustomBox sx={{flexDirection: 'column'}}>
                        <Typography variant={'h5'} sx={sxStyleTypography}>About</Typography>
                        <Typography variant={'h1'} sx={{...sxStyleTypography, ...sxHeaderSize}}>CodeLoci</Typography>
                    </CustomBox>
                </Container>
            </Box>
            <Box sx={{height: 'inherit', py: '4rem', px: {xs: '1rem', md: '5rem', maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto'}}}>
                <Grid>
                    <Grid container sx={sxMainGrid}>
                        <Grid item xs={12} md={5}>
                            <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                CodeLoci is a software development company that provides modern solutions to businesses
                                of all sizes. We have a team of experienced developers who are experts in the latest technologies.
                                We offer a wide range of services, including:
                                <ul>
                                    <li>
                                        Web Development
                                    </li>
                                    <li>
                                        Mobile App Development
                                    </li>
                                    <li>
                                        Custom Software Development
                                    </li>
                                    <li>
                                        Cloud Computing
                                    </li>
                                    <li>
                                        DevOps
                                    </li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={CLLogo} alt={"main_logo"} style={sxStyleImage}></img>
                        </Grid>
                        {!isSmallScreen ? <>
                            <Grid item xs={12} md={5}>
                            <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                CodeLoci is a leading provider of software development solutions, committed to empowering
                                businesses with innovative and modern technologies. We envision a future where businesses
                                of all sizes can leverage software to achieve their goals, seamlessly bridging the gap
                                between cutting-edge technology and practical solutions.

                                We believe that software development should be a collaborative journey between clients
                                and developers, fostering a deep understanding of business needs and aspirations.

                                Through our expertise and client-centric approach, we aim to revolutionize the software
                                development landscape, enabling businesses to thrive in the ever-evolving digital era.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{my: '1rem'}}>
                            <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                We are committed to providing our clients with high-quality, affordable solutions that
                                meet their specific needs. We work closely with our clients to understand their business
                                goals and then develop software that helps them achieve those goals.
                                We offer both B2B and B2C solutions. For our B2B clients, we provide custom software
                                development solutions that help them streamline their operations and improve efficiency.
                                For our B2C clients, we develop mobile apps and web applications that provide a great user experience.
                                We are proud to be a leading provider of software development solutions. We are committed
                                to providing our clients with the best possible service and helping them achieve their business goals.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                Why Choose CodeLoci?
                                <ul>
                                    <li>
                                        We have a team of experienced developers who are experts in the latest technologies.
                                    </li>
                                    <li>
                                        We offer a wide range of services to meet your specific needs.
                                    </li>
                                    <li>
                                        We are committed to providing high-quality, affordable solutions.
                                    </li>
                                    <li>
                                        We work closely with our clients to understand their business goals.
                                    </li>
                                    <li>
                                        We are a trusted provider of software development solutions.
                                    </li>
                                </ul>
                            </Typography>
                            </Grid>
                            </> : <>
                            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <CustomButton onClick={handleButtonClick}>More Information</CustomButton>
                            </Grid>
                            {showGrid && ( <>
                                    <Grid item xs={12} mt={4}>
                                        <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                            CodeLoci is a leading provider of software development solutions, committed to empowering
                                            businesses with innovative and modern technologies. We envision a future where businesses
                                            of all sizes can leverage software to achieve their goals, seamlessly bridging the gap
                                            between cutting-edge technology and practical solutions.

                                            We believe that software development should be a collaborative journey between clients
                                            and developers, fostering a deep understanding of business needs and aspirations.

                                            Through our expertise and client-centric approach, we aim to revolutionize the software
                                            development landscape, enabling businesses to thrive in the ever-evolving digital era.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{my: '1rem'}}>
                                        <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                            We are committed to providing our clients with high-quality, affordable solutions that
                                            meet their specific needs. We work closely with our clients to understand their business
                                            goals and then develop software that helps them achieve those goals.
                                            We offer both B2B and B2C solutions. For our B2B clients, we provide custom software
                                            development solutions that help them streamline their operations and improve efficiency.
                                            For our B2C clients, we develop mobile apps and web applications that provide a great user experience.
                                            We are proud to be a leading provider of software development solutions. We are committed
                                            to providing our clients with the best possible service and helping them achieve their business goals.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={sxStyleTypographyBottom} variant={'body-2'}>
                                            Why Choose CodeLoci?
                                            <ul>
                                                <li>
                                                    We have a team of experienced developers who are experts in the latest technologies.
                                                </li>
                                                <li>
                                                    We offer a wide range of services to meet your specific needs.
                                                </li>
                                                <li>
                                                    We are committed to providing high-quality, affordable solutions.
                                                </li>
                                                <li>
                                                    We work closely with our clients to understand their business goals.
                                                </li>
                                                <li>
                                                    We are a trusted provider of software development solutions.
                                                </li>
                                            </ul>
                                        </Typography>
                                    </Grid> </>
                                )}
                            </>}
                        </Grid>
                    </Grid>
                </Box>
            <Footer></Footer>
        </Box>
    )
}

export default About;