import React from 'react';
import {Box, Container, Grid, Paper, styled, Typography, TextField} from "@mui/material";
import MainWallpaper from './imgs/main-wall4';
import {LocationOnRounded, MailRounded, PhoneRounded} from "@mui/icons-material";
import Footer from "./Footer";

const Contact = () => {

    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: "#022031FF",
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#32EE88FF',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#022031FF',
            },
            '&:hover fieldset': {
                borderColor: '#022031FF',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#022031FF',
                color: '#022031FF',
            },
        },
    });

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const ItemBox = styled(Paper)(({ theme }) => ({
        border: 'solid 1px #32EE88FF',
        borderRadius: '5px',
        backgroundColor: '#022031FF',
        color: '#32EE88FF',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'start',
        boxShadow: 4,
        boxShadowColor: '#32EE88FF',
        elevation: true,
        ...theme.typography.body2,
        padding: theme.spacing(3),
        transition: '0.7s',
        "&:hover" : {
          backgroundColor: '#32EE88FF',
          color: '#022031FF',
        },
    }));

    const sxStyleImage = {
        height: '100%',
        width: '100%',
        position: 'fit',
    }

    const sxLinksStyle = {
        fontFallback: 'sans-serif',
        fontSize: '18px',
    };

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"},
        fontWeight: 'bold'
    }

    const sxStyleText = {
        color: '#32EE88FF',
    }

    return(
        <>
            <Box sx={{height: '45vh',backgroundImage: `url(${MainWallpaper})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container sx={{height: 'inherit'}}>
                    <CustomBox sx={{height: 'inherit', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                        <Typography variant={'h5'} sx={sxStyleText}>Contact</Typography>
                        <Typography variant={'h1'} sx={{...sxStyleText, ...sxHeaderSize}}>CodeLoci</Typography>
                    </CustomBox>
                </Container>
            </Box>
            <CustomBox>
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2} direction={'column'} sx={{my: '2rem'}}>
                        <Grid item xs={12}>
                            <ItemBox>
                                <Typography variant={'h6'}>Contact Us</Typography>
                                <Typography variant={'h3'}>Would you like to initiate a project,
                                    become part of the team, or simply say hello? We'd be delighted to hear from you.
                                </Typography>
                            </ItemBox>
                        </Grid>
                        <Grid item xs={12}>
                            <ItemBox>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Box>
                                            <img src={MainWallpaper} alt={'wallpaper_side'} style={sxStyleImage}/>
                                        </Box>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{px: {xs: '0rem', md: '2rem'}}}>
                                    <Box>
                                        <Typography variant={'h4'}>CodeLoci</Typography>
                                        <Typography variant="body2" display="flex" alignItems="center" alignSelf="start" my="1rem">
                                            <MailRounded sx={{mr: 1}} fontSize="small" />
                                            <a style={sxLinksStyle}>hr@codeloci.com</a>
                                        </Typography>
                                        <Typography variant="body2" display="flex" alignItems="center" alignSelf="start">
                                            <PhoneRounded sx={{mr: 1}} fontSize="small" />
                                            <Typography style={sxLinksStyle}>+92-323-7560427</Typography>
                                        </Typography>
                                        <Typography variant="body2" display="flex" alignItems="center" alignSelf="start" my="1rem">
                                            <LocationOnRounded sx={{mr: 1}} fontSize="small" />
                                            <Typography style={{...sxLinksStyle, textAlign: 'start', alignSelf: 'center'}}>Office# 101, Plaza 83, Neelam Commercial, DC Colony, Rahwali Cantonment, Gujranwala</Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ItemBox>
                        </Grid>
                        {/*<Grid item xs={12} sx={{width: '50%', alignSelf: 'center'}}>
                            <ItemBox>
                                <Typography variant={'h6'} sx={sxTopSectionText}>Email Us</Typography>
                                <CssTextField label="Please, Enter Your Email" id="custom-css-outlined-input" fullWidth />
                                <CssTextField label="Please, Enter a Topic" id="custom-css-outlined-input" fullWidth />
                                <CssTextField label="Please, Enter Your Email" id="custom-css-outlined-input" fullWidth multiline maxRows={4}/>

                            </ItemBox>
                        </Grid>*/}
                    </Grid>
                </Container>
            </CustomBox>
            <Footer></Footer>
        </>
    )
}

export default Contact;