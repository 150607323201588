import React, {useEffect} from 'react';
import {Box, Container, Grid, Icon, styled, Typography} from "@mui/material";
import MainImg from "../imgs/main-wall10.jpeg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeAbout = () => {
    useEffect(() => {
        AOS.init({duration: 1000});
    }, [])

    const CustomBox = styled(Box)(({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        marginX: '40%',
        alignItems: 'center',
        textAlign: 'center',
        gap: theme.spacing(5),
        marginTop: theme.spacing(0.25),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const sxStyleTypography = {
        color: '#32EE88FF',
    };

    return (
        <Box sx={{background: '#022031FF', minHeight: '90vh'}}>
            <Container>
                <CustomBox>
                    <Grid container>
                        <Grid data-aos="fade-down" item xs={12} sx={{alignItems: 'center', justifyContent: 'center', mt: '4rem', mb: '2rem'}}>
                        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 3}}>
                            <Typography sx={{alignItems: 'center',textAlign: 'center',fontWeight: 'bold',color: '#022031FF', borderRadius: '5px', backgroundColor: '#32EE88', py: '5px', px: '20px'}} variant={'body2'}>ABOUT US</Typography>
                        </Box>
                            <Typography sx={{...sxStyleTypography, mb: 3}} variant={'h4'}>Choose The Best IT Services Company</Typography>
                            <Typography sx={{...sxStyleTypography, px: '10%'}} variant={'body1'}>Enhance your business operations and technological capabilities
                                by partnering with the ideal IT services company to provide tailored solutions and support.</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
                            <Grid data-aos="fade-down" container sx={{width: '100%'}}>
                                <Grid item xs={12} sx={{flex: '1.25' ,alignItems: 'center', justifyItems: 'center', width: '100%'}}>
                                    <img src={MainImg} alt={'About'} width={'100%'} height={'100%'} style={{borderRadius: '5px'}}></img>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid data-aos="fade-down" item xs={12} sx={{pl: {md: '10%', xs: '0'}, my: {xs: '2rem', md: '0rem'}, textAlign: 'start'}}>
                                    <Typography sx={sxStyleTypography} variant={'h4'}>Choose The Best IT Services Company</Typography>
                                    <Typography sx={sxStyleTypography} variant={'body1'}>Enhance your business operations and technological capabilities
                                        by partnering with the ideal IT services company to provide tailored solutions and support.</Typography>
                                    <Typography sx={{...sxStyleTypography, textAlign: 'start'}} variant={"body1"}>
                                        We offer a wide range of IT solutions that are customized to meet the specific
                                        needs of our diverse clients. Our clients come from various backgrounds, including
                                        businesses, government organizations, schools, and more. We create creative solutions
                                        that are meant to improve learning, productivity, and knowledge sharing. We use
                                        the latest technology and a deep understanding of our clients' needs to help them
                                        succeed and grow in different fields, making the digital world more connected and
                                        advanced.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomBox>
            </Container>
        </Box>
    )
}

export default HomeAbout;