import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ceo from './imgs/jawad_avatar.jpeg';
import emp1 from './imgs/zunaira_avatar.jpeg';
import empty from './imgs/empty_avatar.jpg';
import empty_female from './imgs/empty_female_avatar.jpg';
import MainImg from "./imgs/main-wall1";
import {Box, Button, Container, styled} from "@mui/material";
import Footer from "./Footer";
import {Link} from "react-router-dom";

const TeamComponent = () => {
    const [cardHovered, setCardHovered] = useState(Array(2).fill(false));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: '#022031FF',
        justifyContent: 'center',
        gap: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
    }));

    const CustomButton = styled(Button)(({ theme }) => ({
        color: '#32EE88FF',
        backgroundColor: '#022031FF',
        border: 'solid 1px #32EE88FF',
        transition: '0.7s',
        boxShadow: '0px 4px 8px rgba(50,238,136,0.5)',
        '&:hover': {
            color: '#022031FF',
            backgroundColor: '#32EE88FF',
            boxShadow: '0px 4px 8px rgba(50,238,136,.5)',
        },
    }));


    const sxStyleTypography = {
        color: '#32EE88FF',
        textAlign: 'center',
    }

    const sxHeaderSize = {
        fontSize: {xs: "48px", sm: "88px", md: "108px"}
    }

    const ceoVar = [
        {
            name: 'Mumshad Jawad',
            role: 'Founder and CEO',
            imageSrc: ceo,
        },
    ];

    const teamMembers= [
        {
            name: 'Munazzah Nawaz',
            role: 'Senior Business Development Officer',
            imageSrc: empty_female,
        },
        {
            name: 'Rashid Chishti',
            role: 'Business Development Officer',
            imageSrc: empty,
        },
        {
            name: 'Sheraz Ahmed',
            role: 'Software Engineer',
            imageSrc: empty,
        },
        {
            name: 'Raiha Batool',
            role: 'Graphic Designer',
            imageSrc: empty_female,
        },
        {
            name: 'Zunaira Zaidi',
            role: 'Business Development Associate',
            imageSrc: emp1,
        },
        {
            name: "Rashda Arshad",
            role: "Web Developer (Intern)",
            imageSrc: empty_female,
        }
    ]

    const cardHover = (index) => {
        const newHoverState = [...cardHovered];
        newHoverState[index] = true;
        setCardHovered(newHoverState);
    };

    const cardHoverLeft = (index) => {
        const newHoverState = [...cardHovered];
        newHoverState[index] = false;
        setCardHovered(newHoverState);
    };

    const styles= {
        team2Section: {
            background: '#022031FF',
        },
        card: {
            borderRadius: '5px',
            backgroundColor: '#022031FF',
            paddingBottom: '2rem',
            transition: '0.7s ease-in-out',
        },

        img1: {
            height: '200px',
            borderTopRightRadius: '5px',
            borderTopLeftRadius: '5px',
            width: '100%',
            transition: 'background-color 0.7s ease-in-out, border-bottom-left-radius 0.7s ease-in-out, border-bottom-right-radius 0.7s ease-in-out',

        },
        img2: {
            position: 'relative',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: '1',
            width: '140px',
            height: '140px',
            borderRadius: '50%',
            marginTop: '-80px',
            transition: 'border-color 0.7s ease-in-out',
        },
        text: {
            padding: '30px 0',
            textAlign: 'center',
        },
        name: {
            textTransform: 'uppercase',
            fontWeight: '900',
            fontSize: '20px',
            margin: '0 0 20px',
            color: '#32EE88FF'
        },
        role: {
            fontSize: '16px',
            padding: '0 35px',
            color: '#32EE88FF'
        },
    };

    const handleButtonClick = () => {

    }

    return (
        <>
        <section id="team2" style={styles.team2Section}>
            <Box sx={{height: '45vh',backgroundImage: `url(${MainImg})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative'}}>
                <Container sx={{height: 'inherit'}}>
                    <CustomBox sx={{height: 'inherit', flexDirection: 'column',alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent'}}>
                        <Typography variant={'h5'} sx={sxStyleTypography}>Crew</Typography>
                        <Typography variant={'h1'} sx={{...sxStyleTypography, ...sxHeaderSize, fontWeight: 'bold'}}>CodeLoci</Typography>
                    </CustomBox>
                </Container>
            </Box>
            <div style={{maxWidth: '1600px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Grid container spacing={4} justifyContent="center" px={3} py={5}>
                    <Grid item xs={12} sm={3} md={4}></Grid>
                    <Grid key={0} item xs={12} sm={6} md={4}>
                        <Card style={{
                            ...styles.card,
                            alignSelf: 'center',
                            width: '100%',
                            border: cardHovered[0] ? 'solid 1px #32EE88FF' : 'solid 1px #32EE88FF',
                            boxShadow: cardHovered[0] ? '0px 4px 8px rgba(50, 238, 136, 0.5)' : '0px 4px 8px rgba(6, 72, 108, 0.5)',

                        }} sx={{
                            width: {xs: '100%', sm: '50%', md: '50%', lg: '40%', xl: '35%'},
                        }} elevation={4}
                              onMouseEnter={() => cardHover(0)}
                              onMouseLeave={() => cardHoverLeft(0)}
                        >
                            <div
                                style={{...styles.img1,
                                    borderBottomLeftRadius: cardHovered[0] ? '50%' : '5px',
                                    borderBottomRightRadius: cardHovered[0] ? '50%' : '5px',
                                    backgroundColor: cardHovered[0] ? '#32EE88FF' : '#043856',
                                }}
                            ></div>
                            <CardMedia
                                component="img"
                                image={ceoVar[0].imageSrc}
                                title={ceoVar[0].name}
                                style={{...styles.img2,
                                    border: cardHovered[0] ? 'solid 7px #32EE88FF' : 'solid 7px #022031FF',
                                    width: '180px',
                                    height: '180px',
                                }}
                            />
                            <div style={styles.text}>
                                <Typography variant="h2" sx={styles.name}>
                                    {ceoVar[0].name}
                                </Typography>
                                <Typography variant="body1" style={styles.role}>
                                    {ceoVar[0].role}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} md={4}></Grid>
                    {teamMembers.map((member, index) => (
                        <Grid key={index} item xs={12} sm={6} md={4}>
                            <Card style={{
                                ...styles.card,
                                border: cardHovered[index+1] ? 'solid 1px #32EE88FF' : 'solid 1px #32EE88FF',
                                boxShadow: cardHovered[index+1] ? '0px 4px 8px rgba(50, 238, 136, 0.5)' : '0px 4px 8px rgba(6, 72, 108, 0.5)',

                            }} elevation={4}
                                  onMouseEnter={() => cardHover(index+1)}
                                  onMouseLeave={() => cardHoverLeft(index+1)}
                            >
                                <div
                                    style={{...styles.img1,
                                        borderBottomLeftRadius: cardHovered[index+1] ? '50%' : '5px',
                                        borderBottomRightRadius: cardHovered[index+1] ? '50%' : '5px',
                                        backgroundColor: cardHovered[index+1] ? '#32EE88FF' : '#043856',
                                    }}
                                ></div>
                                <CardMedia
                                    component="img"
                                    image={member.imageSrc}
                                    title={member.name}
                                    style={{...styles.img2,
                                        border: cardHovered[index+1] ? 'solid 7px #32EE88FF' : 'solid 7px #022031FF',
                                    }}
                                />
                                <div style={styles.text}>
                                    <Typography variant="h2" sx={styles.name}>
                                        {member.name}
                                    </Typography>
                                    <Typography variant="body1" style={styles.role}>
                                        {member.role}
                                    </Typography>
                                </div>
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Link to="/Careers">
                            <CustomButton onClick={handleButtonClick}>Join Our Team</CustomButton>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </section>
            <Footer></Footer>
        </>
    );
};

export default TeamComponent;
